// stylelint-disable selector-class-pattern
@use 'styles/DesignSystem/colors' as colors;

.dig-Checkbox {
  --dig-color__secondary__on-base: #{colors.$dumbo-lighter};
  --dig-color__secondary__base--state-1: #{colors.$dory-light};

  .dig-Checkbox-styles {
    border-radius: 2px;
  }

  .dig-Checkbox-input {
    ~ .dig-Checkbox-styles {
      --dig-color__secondary__base: #{colors.$edna-lighter};
    }
   
    &:checked ~ .dig-Checkbox-styles,
    &:indeterminate ~ .dig-Checkbox-styles {
      --dig-color__secondary__base: #{colors.$dory-light};
      background-color: #{colors.$dory-light};
      border: 1px solid var(--dig-color__secondary__base);
    }
  }
}
