// stylelint-disable selector-class-pattern
@use 'styles/DesignSystem/colors' as colors;

.dig-Typeahead-container {
  border-radius: 4px;
}

.dig-Typeahead-results {
  .dig-Typeahead-row--highlight {
    --dig-color__primary__surface: #{colors.$elsa-light};
  }

  .dig-Typeahead-row {
    --dig-color__text__subtle: #{colors.$edna-light};
    --dig-color__text__base: #{colors.$edna-dark};

    &:focus {
      --dig-utilities__boxshadow__focus-ring: 0 0 0 3px #{colors.$dory-light};
    }

    &:active {
      --dig-color__opacity__surface--state-1: #{colors.$edna-active-transparent};
    }

    &:hover {
      --dig-color__opacity__surface: #{colors.$edna-transparent};
    }
  }
}

.dig-Select-trigger {
  --dig-radius__medium: 4px;
  --dig-color__border__bold: #{colors.$edna-lighter};
}

.dig-Menu-canvas {
  border-radius: 4px;
}

.dig-Select-menu {
  .dig-Menu-row {
    --dig-color__text__base: #{colors.$edna-dark};

    &:focus {
      --dig-utilities__boxshadow__focus-ring: 0 0 0 3px #{colors.$dory-light};
    }

    &:active {
      --dig-color__opacity__surface--state-1: #{colors.$edna-active-transparent};
    }

    &:hover {
      --dig-color__opacity__surface: #{colors.$edna-transparent};
    }
  }
}

.dig-Accordion-header-trigger:focus-visible,
.dig-List-item--isSelectable:focus-visible {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring) !important; // force override DIG box-shadow
}
