.basic-stats {
  img {
    margin-left: 20px;
    width: 40.25%;
  }
}

.basic-stats,
.tv-highlight {
  img {
    max-width: 100%;
  }
}

.press-mentions {
  border-top: 1px solid $light-grey-horizontal-rule-color;
  padding: 80px 0;
}

.tv-highlight {
  border-left: 1px solid $white-grey-color;
  margin-top: -10px;
}

@media screen and (max-width: 769px) {
  .basic-stats {
    padding-bottom: 100px;
    text-align: center;

    img {
      margin-left: 20px;
      width: 130px;
    }

    img:first-child {
      border-right: 1px solid $light-gray-text-color;
      margin-right: 10px;
      padding-right: 10px;
    }
  }

  .press-mentions,
  .tv-highlight {
    border: 0;
  }
}
