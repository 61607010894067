.edit-pdf-upload-divider {
  align-items: center;
  color: $grey-edit-pdf-container-text-color;
  display: flex;
  font-size: 19px;
  justify-content: center;
  margin: 20px 0;
  position: relative;

  &:before,
  &:after {
    background: $edit-pdf-upload-divider-color;
    content: '';
    display: inline-block;
    height: 1px;
    margin-top: 1px;
    position: absolute;
    width: 44%;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.edit-pdf-upload-description {
  font-style: italic;
  text-align: center;
}

@mixin upload-button {
  border: 0;
  border-radius: 3px;
  color: $white;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 16px 30px;

  &:hover {
    cursor: pointer;
  }
}

@mixin blue-upload-button {
  @include upload-button;
  background: linear-gradient(0deg, $edit-pdf-upload-button-light-blue 0%, $edit-pdf-upload-button-dark-blue 100%);

  &:hover {
    background: linear-gradient(180deg, $edit-pdf-upload-button-light-blue 0%, $edit-pdf-upload-button-dark-blue 100%);
  }
}

.edit-pdf-upload-wrapper {
  .edit-pdf-upload-button {
    @include blue-upload-button;

    font-weight: bold;
    height: 54px;
    justify-content: center;
    padding: 0;
    pointer-events: auto;
    width: 100%;

    &:hover {
      background: linear-gradient(180deg, $edit-pdf-upload-button-light-blue 0%, $edit-pdf-upload-button-dark-blue 100%);
      cursor: pointer;
    }
  }
}
