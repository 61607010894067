@import 'utilities/variables';

.header {
  background: $header-color;
  display: flex;
  flex-shrink: 0;
  height: 60px;

  .container {
    align-self: center;
    height: 100%;
    padding: 0;
    width: 940px;
  }

  .row {
    margin: 0;
    width: 100%;
  }

  a > img {
    margin-top: 5px;
    height: 52px;
  }

  span {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.header-account-menu {
  background-color: $white;
  border-radius: 1px 1px 8px 8px;
  box-shadow: 0 0 5px 0 $black;
  color: $header-grey;
  font-size: 13px;
  margin-right: 10px;
  padding: 6px 20px;

  a:active,
  a:hover,
  a:link,
  a:visited {
    color: $header-text-color-blue;
  }

  .log-out-button {
    background-color: $white;
    border: 0;
    color: $header-text-color-blue;
    padding: 0;
  }

  &.freemium-account-menu {
    background-color: $header-color;
    box-shadow: none;
    padding: 6px 0 0;

    a:active,
    a:hover,
    a:link,
    a:visited,
    .freemium-log-out-button {
      color: $freemium-menu-item-text-color;
    }
  }
}

@media (max-width: 335px) {
  .header-account-menu {
    padding: 6px 10px;
  }
}

@media (max-width: $bootstrap-3-xs-width) {
  .header {
    height: 50px;
    padding: 0 10px;

    .container {
      width: 100%;
    }

    a > img {
      height: 40px;
      width: auto;
    }
  }

  .header-account-menu {
    margin-right: 0;
  }
}

@media (max-width: 404px) {
  .header a > img {
    height: 35px;
    width: auto;
  }
}

.header-navigation {
  display: flex;
  
  a:hover {
    color: var(--dig-color__text__base);
  }
}

.my-documents-link {
  align-items: center;
  border-right: 1px solid var(--dig-color__border__subtle);
  display: flex;
  margin-right: var(--spacing__unit--0_5);
  padding-right: var(--spacing__unit--2);

  a {
    display: flex;
    gap: calc(var(--spacing__unit--0_5) / 2);
  }

  a.dig-Link:hover {
    text-decoration: none;
  }
}

.sign-in-button {
  margin-right: var(--spacing__unit--1);
}
