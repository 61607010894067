.sidebar-layout-checkout-wrapper {
  .order-summary-item-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 7px;
    margin-top: 0;
  }

  .order-summary-item {
    font-size: 30px;
    font-weight: 200;
    margin-bottom: 5px;
    padding-bottom: 0;
  }

  .checkout-form-wrapper {
    margin: 0 auto;
    max-width: 560px;
    width: 100%;

    .sub-header {
      color: $sidebar-sub-header-color;
      display: block;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 42px;
    }
  }

  .checkout-form-header {
    margin: 84px 0 36px;

    &,
    h2 {
      color: $edna;
      font-size: 30px;
      font-weight: bold;
      line-height: 42px;
    }

    span {
      color: $checkout-label-color;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .checkout-form-subheader {
    color: $edna;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 36px;
    margin-top: 12px;
  }

  .checkout-form-inner-wrapper {
    border: 1px solid $sidebar-checkout-border-color;
    border-radius: 20px;
    padding: 35px 25px;
    width: $checkout-container-width;
  }

  .form-group {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    width: 49%;

    input {
      margin-bottom: 9px;
      padding: 4px;
    }

    &.odd {
      padding-right: 20px;
    }
  }

  .input-card-cvc {
    padding-right: 110px;
    position: relative;

    .checkout-form-cvc-popover-link {
      position: absolute;
      right: 50px;
      top: 9px;
    }
  }

  .complete-order {
    background-color: transparent;
    display: block;
    margin: 0 auto;

    button {
      margin-left: -15px;
    }
  }

  .checkout-faq-wrapper {
    background: $sidebar-background-grey;
    color: $edna;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
  }

  .checkout-faq-inner-wrapper {
    padding: 60px 30px 42px;
  }

  .checkout-faq-header {
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 36px;
    text-align: center;
  }

  .checkout-faq-question-wrapper {
    border-bottom: 1px solid $sidebar-faq-question-border-color;
    font-size: 14px;
    margin-bottom: 18px;
    position: relative;

    &.active .checkout-faq-info-wrapper {
      display: block;
    }
  }

  .checkout-faq-question {
    display: inline-block;
    font-weight: 600;
    padding-right: 20px;
  }

  .checkout-faq-question-icon {
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
  }

  .checkout-faq-info-wrapper {
    background-color: $sidebar-faq-info-wrapper-background-color;
    border-radius: 3px;
    display: none;
    position: relative;

    .checkout-faq-info-text {
      color: $edna;
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      margin: 19px 0 20px;
      padding: 30px 24px 24px;
    }
  }

  .checkout-faq-info-close {
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    outline: 0;
    position: absolute;
    right: 8px;
    top: 4px;
    transform: rotate(45deg);
  }

  .side-checkout-faq-info-text {
    color: $edna;
    font-weight: 500;
    line-height: 22px;
    margin: 19px 0 20px;
    padding: 30px 24px 24px;
  }

  .checkout-faq-info-arrow {
    border-bottom: 8px solid $sidebar-faq-info-wrapper-background-color;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    height: 0;
    position: absolute;
    right: -1px;
    top: -7px;
    transform: translate(-50%, 0);
    width: 0;
    z-index: 10;
  }

  label {
    color: $checkout-label-color;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 5px;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .input img {
    left: 10px;
    margin-top: -30px;
    position: relative;
    top: 7px;
  }
}

.sidebar-layout-checkout-wrapper-arl {
  .order-summary-item-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 7px;
    margin-top: 0;
  }

  .order-summary-item {
    font-size: 30px;
    font-weight: 200;
    margin-bottom: 5px;
    padding-bottom: 0;
  }

  .checkout-form-wrapper {
    margin: 0 auto;
    max-width: 560px;
    width: 100%;

    .sub-header {
      color: $sidebar-sub-header-color;
      display: block;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 42px;
    }
  }

  .checkout-form-header {
    color: $edna;
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    margin: 84px 0 36px;

    span {
      color: $checkout-label-color;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .checkout-form-inner-wrapper {
    border: 1px solid $sidebar-checkout-border-color;
    border-radius: 20px;
    padding: 35px 25px;
    width: $checkout-container-width;
  }

  .form-group {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    width: 49%;

    input {
      margin-bottom: 9px;
      padding: 4px;
    }

    &.odd {
      padding-right: 20px;
    }
  }

  .input-card-cvc {
    padding-right: 110px;
    position: relative;

    .checkout-form-cvc-popover-link {
      position: absolute;
      right: 50px;
      top: 9px;
    }
  }

  .complete-order {
    background-color: transparent;
    display: block;
    margin: 0 auto;

    button {
      margin-left: -15px;
    }
  }

  .checkout-faq-wrapper {
    background: $sidebar-background-grey;
    color: $edna;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
  }

  .checkout-faq-inner-wrapper {
    padding: 60px 30px 42px;
  }

  .checkout-faq-header {
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 36px;
    text-align: center;
  }

  .checkout-faq-question-wrapper {
    border-bottom: 1px solid $sidebar-faq-question-border-color;
    font-size: 14px;
    margin-bottom: 14px;
    position: relative;

    &.active .checkout-faq-info-wrapper {
      display: block;
    }
  }

  .checkout-faq-question {
    display: inline-block;
    font-weight: 600;
    padding-bottom: 2px;
    padding-left: 24px;
  }

  .checkout-faq-question-icon {
    // background: none;
    border: 0;
    color: #37455b;
    cursor: pointer;
    left: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 2px;
  }

  .checkout-faq-info-wrapper {
    background-color: $sidebar-faq-info-wrapper-background-color;
    border-radius: 3px;
    display: none;
    position: relative;

    .checkout-faq-info-text {
      color: $edna;
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      margin: 4px 0 20px;
      padding: 24px;
    }
  }

  .checkout-faq-info-close {
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    outline: 0;
    position: absolute;
    right: 8px;
    top: 4px;
    transform: rotate(45deg);
  }

  .side-checkout-faq-info-text {
    color: $edna;
    font-weight: 500;
    line-height: 22px;
    margin: 19px 0 20px;
    padding: 30px 24px 24px;
  }

  .checkout-faq-info-arrow {
    border-bottom: 8px solid $sidebar-faq-info-wrapper-background-color;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    height: 0;
    left: 16px;
    position: absolute;
    top: -7px;
    transform: translate(-50%, 0);
    width: 0;
    z-index: 10;
  }

  label {
    color: $checkout-label-color;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 5px;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .input img {
    left: 10px;
    margin-top: -30px;
    position: relative;
    top: 7px;
  }
}
