@use 'styles/utilities/_variables' as common;
@import 'styles/DesignSystem/design-system';
@import 'styles/utilities/variables';
@import 'styles/utilities/bootstrap-override';
@import 'carousel';
@import 'upgrade-plans';
@import 'upgrade-pricing-fax';

.pricing-options-col {
  margin-bottom: 80px;

  &.multi-page-conversion-flow {
    margin-bottom: 40px;

    @include common.lg-and-up {
      margin-bottom: 80px;
    }
  }
}

.pricing-options-wrapper {
  margin: 0 auto;
  max-width: 601px;
  text-align: center;
}

.upgrade-pricing-page-header,
.upgrade-pricing-page-sub-header {
  margin: 0;
}

.upgrade-pricing-page-header {
  color: $edna;
  font-size: 24px;
  font-weight: bold;
  line-height: 42px;
  padding-top: 36px;
}

.upgrade-pricing-page-sub-header {
  color: $light-gray-text-color;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  padding-bottom: 24px;
}

.upgrade-pricing-flex-wrapper {
  display: flex;
  justify-content: center;
}

.upgrade-pricing-quote {
  color: $upgrade-pricing-quote-color;
  font-family: inherit;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  max-width: 400px;
  padding: 60px 0;
  text-align: center;

  span {
    color: $light-gray-text-color;
    display: block;
    font-size: 15px;
    margin-top: 2px;
  }
}

@media (min-width: $bootstrap-md-min-width) {
  .upgrade-pricing-page-header,
  .upgrade-pricing-page-sub-header {
    padding-left: 16px;
  }
}

@media (min-width: $bootstrap-lg-min-width) {
  .pricing-options-wrapper {
    text-align: left;
  }
}
