@import 'styles/DesignSystem/design-system';
@import 'styles/utilities/variables';
@import 'styles/utilities/bootstrap-override';
@import 'drag-and-drop';
@import 'upload-button';
@import 'paper-to-pdf';
@import 'press-mentions';
@import 'benefits';
@import 'upload-modal';

.edit-pdf-container {
  margin: 0 auto;
  max-width: 1024px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 5px 0;
  }
}

@media screen and (max-width: 769px) {
  .edit-pdf-container {
    max-width: 420px;
  }
}
