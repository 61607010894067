// stylelint-disable selector-class-pattern
@use 'styles/DesignSystem/colors' as colors;

.dig-TextInputContainer,
.dig-TextArea {
  --dig-color__border__bold: #{colors.$edna-lighter};
  --dig-color__text__base: #{colors.$edna};
  --dig-radius__medium: 4px;
  --dig-radius__large: 6px;
  --dig-color__primary__surface: #{colors.$elsa-light};
  --dig-color__primary__on-surface: #{colors.$dory-dark};
}

.dig-TextInputContainer:focus-within,
.dig-TextArea:focus {
  --dig-utilities__boxshadow__focus-ring: 0 0 5px 0 #{colors.$dory-light};
  --dig-color__secondary__base: #{colors.$dory-light};
}
