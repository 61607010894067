
& {
  .dig-Modal-content {
    border-radius: 4px;
    @media (max-width: 600px) {
        height: auto;
        min-height: auto;
      }
  }
  
  .dig-Modal-close-btn {
    right: 12px;
    top: 16px;
  }
  
  @media (max-width: 600px) {
    .dig-Modal--isCentered {
        margin: 0;
        top: 20%;
        transform: none;
    }
  }
}
