// All global .scss files should be imported here
@import 'styles/CcpaRequest/ccpa-request';
@import 'styles/Checkout/checkout';
@import 'styles/Confirmation/confirmation';
@import 'styles/EditPdf/edit-pdf';
@import 'styles/footer';
@import 'styles/FreemiumMenu/freemium-menu';
@import 'styles/header';
@import 'styles/MembersArea/members-area';
@import 'styles/NewAccountForm/new-account-page';
@import 'styles/PrivacyPolicy/privacy-policy';
@import 'styles/UpgradePricing/upgrade-pricing';
@import '@dropbox/dig-components/dist/tokens.css';
@import '@dropbox/dig-fonts/dist/index.css';
@import '@dropbox/dig-components/dist/index.web.css';
@import '@dropbox/dig-illustrations/dist/index.web.css';
@import 'styles/DIG/formswift';

html {
  font-size: 14px;
}

body {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

@media (max-width: 600px) {
  .dig-Modal {
      height: auto;
      left: 2.5%;
      max-width: 95%;
      min-height: none;
      top: 20%;
    }
  }