@use 'styles/DesignSystem/_colors' as colors;

// stylelint-disable selector-class-pattern

.dig-Tooltip-content {
  --color__standard__elevated__background: #1d4b7d;
  border-radius: 4px;
  text-align: center;

  .dig-Text--color-standard {
    color: #{colors.$white};
  }
}
