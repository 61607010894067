/* stylelint-disable */
.edit-pdf-upload-wrapper {
  background: $edit-pdf-upload-wrapper-background-color;
  margin: 0 auto;
  max-width: 432px;
  padding: 10%;

  button {
    background: $edit-pdf-upload-box-background-color;
    border-color: $edit-pdf-upload-box-border-color;
    border-radius: 0;

    svg {
      display: none;
    }
  }

  .uppy-DragDrop-inner {
    display: flex;
    flex-direction: column;
    height: 279px;
    justify-content: center;
    padding: 0;
  }

  .uppy-DragDrop-label,
  .uppy-DragDrop-note {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
  }

  .uppy-DragDrop-label {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 8px;
  }

  .uppy-DragDrop-note {
    color: $edit-pdf-upload-box-note-color;
    font-size: 17px;
    margin-bottom: 0;

    &::after {
      content: url('/static/images/edit-pdf/icon-drag-drop.png');
      display: block;
    }
  }

  .uppy-DragDrop--isDraggingOver {
    border-color: $edit-pdf-upload-box-active-border-color;
  }

  .upload-container-inner {
    border: 2px dashed $edit-pdf-upload-box-border-color;
    border-radius: 4px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 292px;
    background: $edit-pdf-upload-box-background-color;

    div:first-child {
      width: 100%;
      height: 100%;
    }

    .drag-drop-container {
      position: absolute;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .drag-drop-label {
      font-family: Lato, Helvetica, Arial, sans-serif;
      line-height: 1.1;
      margin-bottom: 8px;
    }

    .drag-drop-note {
      font-family: Lato, Helvetica, Arial, sans-serif;
      font-size: 17px;
      color: $edit-pdf-upload-box-note-color;
    }

    .uppy-DragDrop-inner {
      display: none;
    }

    .uppy-DragDrop-label,
    .uppy-DragDrop-note {
      display: none;
    }
    .uppy-Root {
      opacity: 0;
      z-index: 1;
    }
  }
}
