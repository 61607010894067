@import 'styles/DesignSystem/design-system';
@import 'styles/utilities/variables';

.sidebar-icon {
  display: block;
  margin: 18px auto;
  width: 100px;

  &.dropbox {
    align-items: center;
    background: var(--dig-color__primary__surface--state-2);
    border-radius: 50%;
    display: flex;
    height: 100px;
    justify-content: center;
    margin: 0 auto;
    width: 100px;
  }
}
