// Import this file to override bootstrap 3 fonts to bootstrap 2
// Make sure to not import this globally

@mixin bootstrap-font-override($size, $line-height) {
  font-size: $size;
  line-height: $line-height;
}

p {
  @include bootstrap-font-override(13px, 18px);
}

h3 {
  @include bootstrap-font-override(18px, 27px);
}

h4 {
  @include bootstrap-font-override(14px, 18px);
}

hr {
  border-bottom: 1px solid $white;
}

.no-padding {
  padding: 0;
}
