.edit-pdf-container .benefits-container {
  font-size: 20px;
  line-height: 1.2;

  p {
    font-size: 20px;
    line-height: 1.2;
  }

  h1 {
    color: $edit-pdf-benefits-headline-color;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: 0;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
  }

  .benefits-list {
    margin: 20px 0 16px;
  }
  
  .item {
    display: flex;
    left: -20px;
    position: relative;
    white-space: nowrap;
  }

  .item:not(:last-child) {
    margin-bottom: 2px;
    padding-left: 12px;
  }

  .description {
    line-height: 2.2;
    margin: 0;
    padding-left: 9px;
  }
}
