.paper-to-pdf-content {
  p {
    color: $grey-edit-pdf-container-text-color;
    font-size: 14px;
    line-height: 24px;
  }

  .alert {
    margin-bottom: 18px;
    padding: 8px 35px 8px 14px;
  }
}

.paper-to-phone {
  img {
    max-width: 100%;
  }
}

.photo-to-image {
  border-top: 1px solid $light-grey-horizontal-rule-color;
  padding-bottom: 50px;
  padding-top: 50px;

  h2 {
    width: 352px;
  }

  .paper-to-phone {
    margin-left: 120px;
  }
}
