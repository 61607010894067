@import 'styles/DesignSystem/design-system';
@import 'styles/utilities/variables';

.members-area-container {
  margin-bottom: 40px;
  margin-top: 41px;
  max-width: 1140px;
  width: 100%;
}

.members-area-tabs > a {
  color: #337ab7;
  line-height: 18px;
  padding: 8px 12px;
  position: relative;

  &:active {
    color: #495057;
  }

  // Ported from Bootstrap 3
  &:hover {
    background-color: #eee;
    color: #23527c;
  }
}

.members-area-tabs>a:nth-child(2)::before {
  background-color: $elsa-light;
  border-radius: 12px;
  bottom: 31px;
  color: $fillable-fields-new-pill-color;
  content: 'New!';
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  left: 34px;
  padding: 0 7px;
  position: absolute;
  text-transform: uppercase;
}


@include md-and-up {
  .members-area-tabs > a {
    font-size: 13px;
  }
}
