@use '../utilities/_variables' as common;

.freemium-menu-wrapper {
  padding-top: 12px;
  position: relative;
  text-align: center;
  width: 100%;

  // Z-index of 2001 renders the freemium menu on top of all FreeWrite toolbars,
  // which have z-index values between ~1000 and ~2000 to compete with bootstrap dropdowns.
  // In the future, we'll need to standardize and manage all z-index values globally.
  z-index: 2001;
  @include common.sm-and-up {
    min-width: 298px;
  }
}

.freemium-menu-email {
  color: common.$white;
  display: inline-block;
  margin-bottom: 0;
  max-width: 134px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include common.md-and-up {
    margin-bottom: 1rem;
    max-width: 100%;
    overflow: visible;
  }
}

.freemium-menu-dropdown-wrapper {
  background: common.$white;
  border-radius: 6px;
  box-shadow: 0 3px 7px common.$freemium-menu-drop-shadow-color;
  width: 100%;
}

.freemium-account-menu {
  a,
  .freemium-log-out-button {
    border-bottom: 1px solid common.$freemium-menu-item-border-color;
    display: block;
    padding: 14px 0 14px 15px;
    text-align: left;

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:hover {
      background-color: common.$freemium-menu-item-hover-background-color;
      text-decoration: none;
    }
  }

  .freemium-log-out-button {
    background-color: $white;
    border: 0;
    width: 100%;
  }
}
