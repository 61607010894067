@import 'styles/utilities/variables';

.confirmation-page {
  min-height: 480px;

  .confirmation-info-container {
    background: $white-smoke-bg;
    border: 1px solid $transparent-black;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px $transparent-black;
    padding: 18px;
  }

  .contact-us-header {
    margin-top: 0;
  }
}
