// stylelint-disable selector-class-pattern
// stylelint-disable scss/selector-no-redundant-nesting-selector

@use 'styles/DesignSystem/colors' as colors;

& {
  --dig-color__text__base: #{colors.$edna-dark};
  --dig-color__text__subtle: #{colors.$edna-light};
  --dig-color__background__subtle: #{colors.$dumbo-light};

  --dig-color__primary__surface: #{colors.$elsa-light};
  --dig-color__primary__on-surface: #{colors.$dory-dark};
  --dig-color__primary__surface--state-1: #{colors.$elsa};
  --dig-color__primary__surface--state-2: #{colors.$elsa-dark};

  --dig-color__success__base: #{colors.$peter-pan-darker};
  --dig-color__secondary__base: #{colors.$edna-dark};

  --dig-color__border__base: #{colors.$dumbo-darker};
  --dig-color__border__subtle: #{colors.$dumbo};

  --dig-color__opacity__surface: #{colors.$edna-transparent};
  --dig-color__opacity__surface--state-1: #{colors.$edna-active-transparent};
  --dig-color__opacity__surface--state-2: #{colors.$edna-more-active-transparent};

  --dig-color__warning__surface: #{colors.$simba-light};

  --dig-color__border__bold: #{colors.$edna-lighter};

  --dig-utilities__boxshadow__focus-ring: 0 0 5px 0 #{colors.$dory-light};
}
