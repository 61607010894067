.upgrade-pricing-carousel {
  background: $sidebar-background-grey;
  height: 100%;
  padding-top: 30px;

  h1 {
    color: $edna;
    font-size: 22px;
    font-weight: 500;
    padding: 0;
    text-align: center;
    text-transform: none;
  }

  h3,
  p {
    color: $gray-text-color;
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    text-shadow: none;
  }

  img {
    margin: 0 auto;
    padding-top: 18px;
  }

  .carousel-caption {
    position: static;
  }

  .carousel-indicators {
    bottom: 0;

    li {
      background-color: $sidebar-carousel-control-color;
      border: 0;
      border-radius: 10px;
      height: 10px;
      margin: 0 5px;
      opacity: 1;
      transition: none;
      width: 10px;

      &.active {
        background-color: $sidebar-carousel-control-active-color;
        height: 10px;
        width: 10px;
      }
    }
  }
}

.carousel-item {
  display: none;

  &.active {
    display: block;
  }

  img {
    box-sizing: content-box;
    height: 100px;
    padding: 18px 0;
    width: 100px;
  }
}

.sidebar-carousel-wrapper {
  text-align: center;
}

@media (min-width: $bootstrap-lg-min-width) {
  .upgrade-pricing-carousel {
    padding-top: 90px;
  }
}
