@import 'utilities/variables';
@import 'styles/DesignSystem/colors';

footer {
  margin-top: 0;
  text-align: center;

  h2 {
    color: $white;
    display: block;
    font-size: 15px;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;

    li {
      margin-bottom: 2px;
    }
  }

  a {
    color: $footer-light-gray;

    &:hover {
      color: $footer-light-gray;
    }
  }
}

.footer-links-container {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}

.dark-background {
  background-color: $footer-dark-blue;
  color: $white;
  text-align: left;
}

.social-media-container-footer {
  background-color: $social-media-container-color;
  margin-top: 42px;
  padding: 15px;

  img {
    margin-bottom: 20px;
    margin-right: 12px;
  }
}

.track-footer-link {
  font-size: 13px;
}

.press-logos {
  margin-bottom: 5px;
  opacity: 0.4;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  text-align: center;

  img {
    margin-right: 4%;
  }
}

.footer-col-margin {
  margin-right: 3%;
}

@media (min-width: $bootstrap-3-xs-width) {
  .social-media-container-footer {
    margin-top: 0;
  }
}
