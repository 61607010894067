// Utilities
@import 'styles/utilities/variables';
@import 'styles/DesignSystem/colors';
@import 'sidebar';

.cc-update-form,
.lof-form-wrap {
  .form-group {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    width: 49%;

    &:nth-child(2n + 1),
    &.odd,
    &.odd {
      padding-right: 20px;
    }

    input {
      box-sizing: border-box;
      height: 28px;
      width: 100%;
    }
  }

  .zip-code-container.zip-code-container {
    display: inline-block;
    padding-right: 0;
    vertical-align: top;
    width: 30%;
  }

  .checkout-legal-info {
    border-top: 1px solid $contact-info-color;
    margin-top: 18px;
    padding-top: 18px;
  }

  .card-number-img {
    margin-bottom: 12px;
    width: 100%;
  }

  .input-card-cvc {
    padding-right: 110px;
    position: relative;

    .checkout-form-cvc-popover-link {
      position: absolute;
      right: 50px;
      top: 9px;
    }
  }

  .form-wrap-left-time-counter {
    color: $checkout-countdown-timer-color;
  }

  #completeOrder {
    display: block;
    margin: 0 auto;
  }
}

.form-group .card-zip input {
  width: 120px;
}

.input-card-cvc {
  position: relative;
}

.input-card-cvc-container {
  vertical-align: top;
}

.checkout-form-cvc-popover-link {
  height: 24px;
  position: absolute;
  right: 60px;
  top: 10px;
  width: 60px;
}

.checkout-form-expiry-field-gray-placeholder {
  color: $gray-placeholder-color;
}

.checkout-form-input-gray-placeholder::-webkit-input-placeholder {
  // Chrome/Opera/Safari
  color: $gray-placeholder-color;
}

.checkout-form-input-gray-placeholder::-moz-placeholder {
  // Firefox 19
  color: $gray-placeholder-color;
}

.checkout-form-input-gray-placeholder:-ms-input-placeholder {
  // IE 10
  color: $gray-placeholder-color;
}

.checkout-form-input-gray-placeholder:-moz-placeholder {
  // Firefox 18-
  color: $gray-placeholder-color;
}

.checkout-form-container {
  .checkout-contact-info input {
    font-size: 13px;
    padding: 4px;
  }

  .checkout-contact-info .form-group {
    vertical-align: top;
  }

  .form-group.focus:not(.is-invalid-input) label {
    color: $blue-active-label-color;
    font-weight: bold;
  }

  .form-group.focus:not(.is-invalid-input) input:focus,
  .validation-select select:focus {
    border: solid 2px $blue-active-input-color;
    -moz-box-shadow: 0 0 6 $blue-active-input-shadow-color;
    -webkit-box-shadow: 0 0 6 $blue-active-input-shadow-color;
    box-shadow: 0 0 6 $blue-active-input-shadow-color;
    outline: none;
  }

  .form-group.is-invalid-input label,
  .expiry-field-row-wrapper.is-invalid-select label {
    color: $red-error-label-color;
    font-weight: bold;
  }

  .form-group .form-error.is-visible {
    color: $red-error-label-color;
    font-size: 14px;
    line-height: 18px;
  }

  .form-group.is-invalid-input .form-label {
    color: $red-error-label-color;
  }

  .form-group.is-invalid-input input,
  .expiry-field-row-wrapper.is-invalid-select select {
    border: solid 2px $red-error-input-color;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .checkout-terms-of-use-checkbox-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-top: 14px;
  }

  .checkout-terms-of-use-checkbox-label {
    padding-left: 12px;
  }

  .checkout-terms-of-use-checkbox-error-message {
    color: $red-error-label-color;
  }
}

.expiry-wrapper.input div {
  display: inline;
}

.expiry-wrapper.input .form-error {
  display: block;
}

.expiry-wrapper .card-expiry-field {
  display: inline;
  height: 28px;
  padding: 4px 12px;
  width: 80px;
}

.card-cvc + .form-error {
  white-space: nowrap;
}

.card-number-and-expiry-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.order-summary-wrapper {
  align-items: center;
  border: 1px solid $sidebar-checkout-border-color;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: 30px;
  padding: 35px 25px;
  width: $checkout-container-width;

  &.freewrite {
    padding: 10px 25px;
  }
}

.checkout-form-cvc-popover-wrap {
  color: $black;

  h4 {
    background-color: $checkout-cvc-popover-background-color;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    margin: 0;
    padding: 7px 15px;
    text-align: left;
    width: 100%;
  }

  div {
    background-color: $white;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 100%;
    margin: 0;
    padding: 15px;
    text-align: left;
    width: 100%;

    p {
      font-size: 13px;
      margin: 0;
    }
  }
}

.checkout-form-cvc-tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    max-width: 100%;
    padding: 3px;
    width: 284px;
  }
}

.checkout-error-container {
  position: absolute;
  width: 100%;

  .alert {
    margin: 0 auto;
    width: 80%;
  }
}

.cart-item-wrapper {
  align-items: center;
  background-color: $white;
  border: 1px solid $cart-item-border-color;
  border-radius: 30px;
  display: flex;
  justify-content: left;
  margin-bottom: 11px;
  padding: 13px 25px;
  width: $checkout-container-width;

  img {
    margin-right: 14.5px;
    width: 29px;
  }

  p {
    color: $cart-item-text-color;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    text-align: center;
  }
}

.order-summary-item-wrapper {
  display: inline-block;
  width: 49%;
}

.order-summary-header {
  font-size: 16px;
}

.order-summary-price {
  font-size: 30px;
  font-weight: 300;
  padding-right: 9px;
}

.checkout-faq-answer,
.checkout-faq-question {
  font-size: 13px;
}

footer {
  padding-top: 0;
}

@media (min-width: $bootstrap-lg-min-width) {
  .sidebar-layout-checkout-wrapper {
    min-height: 1060px;
  }
}

@media (max-width: $sidebar-min-desktop-width) {
  .sidebar-layout-checkout-wrapper {
    .bootstrap-sm-9.checkout-form-outer-wrapper {
      width: 100%;
    }
  }

  .checkout-error-container {
    position: relative;
    width: 100%;

    .alert {
      margin: 0 auto;
      width: 100%;
    }
  }
}

@media (max-width: $bootstrap-lg-min-width) {
  .sidebar-layout-checkout-wrapper {
    .checkout-faq-inner-wrapper {
      padding-left: 30px;
      padding-right: 30px;
    }

    .checkout-faq-wrapper {
      margin-top: 25px;
      padding-top: 0;
    }
  }
}

@media (max-width: $bootstrap-3-xs-width) {
  .sidebar-layout-checkout-wrapper {
    .order-summary-wrapper {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
    }

    .checkout-form-header {
      text-align: center;
    }

    .checkout-form-inner-wrapper,
    .cart-item-wrapper {
      width: auto;
    }

    .card-number-img {
      float: none;

      img {
        margin: 0 0 9px -5px;
      }
    }

    .form-group,
    .form-group:nth-child(2n + 1),
    .form-group.odd {
      padding: 0;
      width: 100%;
    }

    .checkout-faq-header {
      margin-top: 0;
    }

    .checkout-faq-wrapper {
      margin-left: 15px;
      margin-right: 15px;
      min-height: 500px;
      padding-right: 0;
    }
  }

  .card-cvc + .form-error {
    position: relative;
    top: 0;
  }

  .form-group .card-zip input {
    width: 100%;
  }
}
