@import 'styles/DesignSystem/colors';

.privacy-policy-container {
  margin-bottom: 80px;

  h1 {
    font-size: 30px;
    margin: 1.5rem 0 0.75rem;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }

  .ccpa-link {
    color: $dory-light;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
  }
}
