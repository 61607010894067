@use 'styles/DesignSystem/_colors' as colors;

& {

  .dig-Banner,
  .dig-Banner--basic,
  .dig-Banner--withCloseButton {
    border-radius: 4px;
    padding: 12px;
  }
  
  .dig-Banner .dig-Banner-close {
    right: 8px;
    top: 8px;
  }
  
  .dig-Banner--neutral {
    background-color: #{colors.$elsa-light};
  }

  .dig-Banner--success {
    background-color: #{colors.$peter-pan-lighter};
    border: 2px solid #{colors.$peter-pan-light};
  }

  .dig-Banner--alert {
    background-color: #{colors.$sebastian-lighter};
    border: 2px solid #{colors.$sebastian-light};
  }
}
