@use 'styles/DesignSystem/_colors' as colors;
// stylelint-disable selector-class-pattern
.dig-DatePicker {
  border-radius: 4px;
}

.dig-DatePicker-day--selected {
  --dig-color__opacity__surface: #{colors.$blue-hover-background-color};
  --dig-color__secondary__on-base: #{colors.$white-hover-text-color};
  background-image: linear-gradient(180deg, #08c, #04c);
  border-radius: 4px;
}

.dig-DatePicker-day:hover:not(.dig-DatePicker-day--selected):not(.dig-DatePicker-day--blocked) {
  --dig-color__opacity__surface: #{colors.$edna-transparent};
  border-radius: 4px;
}

.dig-IconButton:hover::before {
  --dig-color__opacity__surface: #{colors.$edna-transparent};
  border-radius: 4px;
}
