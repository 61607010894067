@use 'styles/DesignSystem/_colors' as colors;

// stylelint-disable selector-class-pattern
.dig-Link--primary {
  --dig-color__primary__base: #{colors.$dory};
}

.second-action-modal-image-bg {
  --dig-color__background__subtle: #{colors.$dumbo-light};
}

.dig-TextInputContainer--isFocused {
  --dig-color__primary__surface: #{colors.$white};
  --dig-color__primary__on-surface: #{colors.$edna-dark};
}
