@import 'styles/DesignSystem/colors';
@import 'styles/utilities/variables';

.ccpa-request-container {
  color: $edna;
  font-family: 'Lato', sans-serif;
  padding: 50px 10px 75px;

  h3 {
    font-size: 28px;
    font-weight: bold;
    line-height: 35px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  p,
  li {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0;
  }

  .part-one-description {
    margin-bottom: 0;
    margin-left: 10px;
  }

  .part-two-description {
    margin-left: 10px;
    margin-top: 0;
  }

  .boldest {
    font-weight: 900;
  }

  .main-cta-button {
    background: linear-gradient(180deg, $secondary-btn-color 0%, $secondary-btn-color-dark 100%);
    border: 0;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 15px;

    &.deletion-button {
      background: linear-gradient(180deg, $lighter-red-button-color 0%, $light-red-button-color 100%);
      color: $white;
    }
  }

  .contact-us-button {
    margin-right: 13px;
  }
}
