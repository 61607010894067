@use 'styles/DesignSystem/_colors' as colors;
// stylelint-disable selector-class-pattern
// stylelint-disable scss/selector-no-redundant-nesting-selector
& {
  --dig-color__primary-cta__base: #{colors.$peter-pan-dark};
  --dig-color__primary-cta__base--state-1: #{colors.$peter-pan-darker};
  --dig-color__primary-cta__base--state-2: #1d5c1d;
  --dig-color__primary-cta__on-base: #{colors.$white};

  .dig-Button,
  .dig-Button--large,
  .dig-IconButton {
    --dig-radius__large: var(--spacing__unit--0_5);
    --dig-radius__medium: var(--spacing__unit--0_5);
    --dig-radius__small: var(--spacing__unit--0_5);
    font-family: Lato, Helvetica, Arial, sans-serif;

    &:focus-visible {
      box-shadow: var(
        --dig-utilities__boxshadow__focus-ring
      ) !important; // force override DIG box-shadow
      clip-path: unset;
    }
  }

  .dig-Button--primary {
    background: linear-gradient(
      180deg,
      var(--dig-color__primary-cta__base) 0%,
      var(--dig-color__primary-cta__base--state-1) 100%
    );
  }

  .dig-Button--opacity {
    background: linear-gradient(
      180deg,
      var(--dig-color__opacity__surface),
      var(--dig-color__opacity__surface--state-2)
    );
    font-weight: normal;
  }

  .dig-Button--borderless {
    font-size: smaller;
  }

  .dig-Button--destructive {
    --dig-color__primary-cta__base: #{colors.$sebastian};
    --dig-color__primary-cta__base--state-1: #{colors.$sebastian-dark};
    --dig-color__primary-cta__base--state-2: #{colors.$sebastian-darker};
  }

  .dig-Button--dark {
    --dig-color__primary-cta__base: #{colors.$edna};
    --dig-color__primary-cta__base--state-1: #{colors.$edna-dark};
    --dig-color__primary-cta__base--state-2: #{colors.$edna-darker};
  }

  .dig-Button--secondary {
    --dig-color__primary-cta__base: #{colors.$dory-lighter};
    --dig-color__primary-cta__base--state-1: #{colors.$dory};
    --dig-color__primary-cta__base--state-2: #{colors.$dory-dark};
  }
}
