// stylelint-disable selector-class-pattern
@use 'styles/DesignSystem/colors' as colors;

.dig-Radio {
  --dig-color__secondary__on-base: #{colors.$dory-light};
  --dig-color__secondary__base: #{colors.$edna-lighter};

  &:checked {
    --dig-color__secondary__base: #{colors.$dumbo-lighter};
    background-color: var(--dig-color__secondary__on-base);
    border: 2px solid var(--dig-color__secondary__on-base);
    /**
    * DIG isn't using variables for offset values.
    * Including entire definition with only offset value changes.
    */
    box-shadow: inset 0 0 0 6px #0000,
    inset 0 0 0 2px var(--dig-color__secondary__base);
  }

  &:focus {
    --dig-color__text__base: #{colors.$dory-light};
    --dig-color__utility__focus-ring: #{colors.$dory-light};
    --dig-color__opacity__surface--state-1: #{colors.$dumbo-lighter};

    /**
     * Adding whole definition here for specificity reasons.
     * We are force applying (!important) this rule to override box shadow definition of other generic class.
     */
    box-shadow: inset 0 0 0 1px var(--dig-color__text__base),
      var(--dig-utilities__boxshadow__focus-ring) !important;
  }

  &:checked:focus {
    --color__core__primary: #{colors.$dumbo-lighter};
    --dig-color__opacity__surface--state-2: #{colors.$dumbo-lighter};

    /**
     * DIG isn't using variables for offset values.
     * Including entire definition with only offset value changes.
     * !important is needed here for specificity reasons.
     */
    box-shadow: inset 0 0 0 2px var(--dig-color__opacity__surface--state-2),
      inset 0 0 0 2px var(--color__core__primary) !important;
  }
}
