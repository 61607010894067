@use 'styles/DesignSystem/_colors' as colors;
// stylelint-disable selector-class-pattern
// stylelint-disable scss/selector-no-redundant-nesting-selector
& {
  $fontfamily-lato: Lato, Helvetica, Arial, sans-serif;
  --type__body__xsmall--fontfamily: #{$fontfamily-lato};
  --type__body__small--fontfamily: #{$fontfamily-lato};
  --type__body__standard--fontfamily: #{$fontfamily-lato};
  --type__body__large--fontfamily: #{$fontfamily-lato};
  --type__body__xlarge--fontfamily: #{$fontfamily-lato};
  --type__body__standard--fontweight_strong: 600;
  --type__body__small--fontsize: 13px;
  --type__title__small--fontfamily: #{$fontfamily-lato};
  --type__title__small--fontweight_strong: 700;
  --type__title__standard--fontsize: 24px;
  --type__title__standard--fontfamily: #{$fontfamily-lato};
  --type__title__large--fontfamily: #{$fontfamily-lato};
}
